import React, { HTMLAttributes } from 'react'
import Link from '@partials/Link'
import Arrow from '@partials/Arrow'

interface Props extends HTMLAttributes<HTMLButtonElement> {
  className?: string
  href?: string
  elType?: 'button' | 'submit' | 'reset'
  wowDelay?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const Button: React.FC<Props> = ({ href, elType, children, className, wowDelay, onClick, ...restProps }) => {
  const ElementTag = href ? Link : 'button'
  const hasArrow = className?.includes('has-arrow')
  const classes = `btn is-light ${hasArrow ? 'has-icon' : ''} ${className ?? ''}`

  //eslint-disable-next-line
  const ButtonProps: any = {
    href,
    onClick,
    type: elType,
    className: classes,
  }

  return (
    <ElementTag {...ButtonProps} {...restProps} data-wow-delay={wowDelay ?? 0}>
      {children}
      {hasArrow ? (
        <div className="arrow-container">
          <Arrow />
          <Arrow />
        </div>
      ) : null}
    </ElementTag>
  )
}

export default Button
