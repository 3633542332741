import React from 'react'
import Button from '@button'
import { WorkObject } from '@types'

import '@sass/components/works/WorksGrid.scss'

type Props = {
  items: WorkObject[]
}

const WorksGrid: React.FC<Props> = ({ items }) => (
  <div className="columns is-multiline works-grid">
    <p className="is-hidden-tablet" style={{ padding: '0 15px' }}>
      Tap to show more info*
    </p>
    {items.map(({ id, title, image, text, tags, color, slug }) => (
      <div key={id} className="column is-6 work wow fadeIn" data-wow-duration="1.5s" data-wow-delay="0.5s">
        <div
          className="work__inner w-mb-100"
          style={{
            backgroundImage: `url(${image})`,
          }}
        >
          <h3 className="work__title">
            <div className="tags-wrapper">
              {tags?.splice(0, 4).map((tag, index) => (
                <span key={index} className="work__tag">
                  {tag}
                </span>
              ))}
            </div>
            {title}
          </h3>
          <div className="work__popup-content">
            <p className="work__excerpt">{text}</p>
            <Button title={title} href={slug} className="has-arrow">
              Read more
            </Button>
          </div>
          <div
            className="work__overlay"
            style={{
              backgroundColor: color,
            }}
          ></div>
        </div>
      </div>
    ))}
  </div>
)

export default WorksGrid
